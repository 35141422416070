import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";

const SuccessfulVipSubModal = ({ renewalDetails, closeModal }) => {
  const { extendedTo, extededFrom, extension, inrPrice } =
    renewalDetails.renewal;
  const { price } = renewalDetails.payment;

  // Format date as DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
  };
  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Renew VIP Subscription</h1>
            <button
              onClick={closeModal}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className="p-4 text-center">
            <div className="loading-gif-new">
              <img src="/images/success.gif" alt="gif" loading="lazy" />
            </div>
            <h2 className="text-xl font-semibold text-green-600 mb-2">
              Subscription Renewal Done!
            </h2>
            <p className="text-sm text-gray-600 mb-4 ">
              Your VIP subscription has been renewed successfully.
            </p>

            {/* Renewal Details */}
            <div className="text-left space-y-3 sm:space-y-2 text-sm sm:border py-4 sm:p-4 rounded-lg sm:bg-gray-50">
              <span className="flex gap-2 w-full justify-between">
                <span className="font-semibold w-36 sm:w-56">
                  Validation From
                </span>{" "}
                <span>:</span>
                <span className="w-36 sm:w-56 text-right">
                  {formatDate(extededFrom)}
                </span>
              </span>
              <span className="flex gap-2 w-full justify-between">
                <span className="font-semibold w-36 sm:w-56">End Date</span>{" "}
                <span>:</span>
                <span className="w-36 sm:w-56 text-right">
                  {formatDate(extendedTo)}
                </span>
              </span>
              <span className="flex gap-2 w-full justify-between">
                <span className="font-semibold w-36 sm:w-56">
                  Duration Days
                </span>{" "}
                <span>:</span>
                <span className="w-36 sm:w-56 text-right">
                  {extension} days
                </span>
              </span>
              <span className="flex gap-2 w-full justify-between">
                <span className="font-semibold w-36 sm:w-56">Amount Paid</span>{" "}
                <span>:</span>
                <span className="w-36 sm:w-56 text-right">{price} </span>
              </span>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-center p-4">
            <button
              onClick={closeModal}
              className="w-full sm:w-44 h-10 bg-custom-border hover:bg-opacity-85 rounded-xl text-white"
            >
              Close
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default SuccessfulVipSubModal;
