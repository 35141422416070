import React, { useEffect, useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import {
  getRenewOptionsList,
  renewCheckout,
} from "../../services/manageVipServices";
import { getUser } from "../../services/auth";
import { useAppContext } from "../../context/AppContext";
import { X } from "lucide-react";

const WarningVipSubModal = ({ subsId, selected, setCheckoutDetails }) => {
  const { setModal } = useAppContext();

  const checkoutMutation = useMutation(
    async () => {
      const user = await getUser();
      return await renewCheckout(user, selected?._id, subsId._id);
    },
    {
      onSuccess: (data) => {
        if (data?.status) {
          setModal("checkout");
          setCheckoutDetails(data.data);
        } else {
          toast.error(data.message);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const handleAccept = () => {
    checkoutMutation.mutate();
  };

  // Format date as DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Renew VIP Subscription</h1>
            <button
              onClick={() => setModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className="p-4">
            <div className="text-center my-6">
              <p className="text-sm text-gray-600">
                Your current VIP subscription is valid until{" "}
                <span className="font-semibold text-black">
                  {formatDate(selected?.subscriptionExpiresAt)}
                </span>
                . If you proceed, the new subscription will be added to your
                current subscription, extending its end date.
              </p>
              <p className="mt-2 text-sm text-red-500">
                Are you sure you want to continue?
              </p>
            </div>

            <div className="flex justify-center items-center gap-4 mt-12 mb-2 text-sm">
              <button
                disabled={checkoutMutation.isLoading}
                onClick={handleAccept}
                className={`bg-custom-border hover:bg-opacity-85 rounded-xl w-44 h-10 text-white disabled:cursor-not-allowed ${
                  checkoutMutation.isLoading ? "bg-gray-400" : ""
                }`}
              >
                {checkoutMutation.isLoading ? "Processing..." : "Accept"}
              </button>
              <button
                onClick={() => setModal("")}
                className="w-44 h-10 border border-custom-border rounded-xl hover:bg-black/5"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default WarningVipSubModal;
