import React, { useEffect, useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { useAppContext } from "../../context/AppContext";
import { User } from "lucide-react";
import {
  getRenewOptionsList,
  renewCheckout,
} from "../../services/manageVipServices";
import { getUser } from "../../services/auth";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

const RenewVipSubModal = ({
  selected,
  setCheckoutDetails,
  subsId,
  setSubsId,
}) => {
  // const [subsId, setSubsId] = useState("");
  const { setModal } = useAppContext();

  const { data: renewOptions } = useQuery({
    queryKey: ["renewOptions", selected._id],
    queryFn: async () => {
      const user = await getUser();
      return await getRenewOptionsList(user);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (renewOptions?.data && renewOptions.data.length > 0) {
      setSubsId(renewOptions.data[0]); // Set first option as default
    }
  }, [renewOptions, setSubsId]);

  // console.log("selected", selected);
  // console.log("renewOptions", renewOptions?.data[0]?._id);

  const checkoutMutation = useMutation(
    async () => {
      const user = await getUser();
      return await renewCheckout(user, selected?._id, subsId._id);
    },
    {
      onSuccess: (data) => {
        if (data?.status) {
          // console.log(data);
          setModal("checkout");
          setCheckoutDetails(data.data);
        } else {
          // console.warn("Help",data);
          toast.error(data.message);
        }
      },
      onError: (error) => {
        // console.error(error);
        toast.error(error.message);
      },
    }
  );

  const handleCheckout = () => {
    if (!subsId || !subsId._id) {
      toast.error("Please select a subscription option.");
      return;
    }

    const extendedToDate = new Date(selected?.subscriptionExpiresAt);
    const currentDate = new Date();

    if (extendedToDate > currentDate) {
      setModal("warning");
    } else {
      checkoutMutation.mutate();
    }
  };

  const balance =
    selected?.role === "USER"
      ? selected?.stockBalance
      : selected?.partnerBalance;

  return (
    <>
      <ModalLayout maxWidth="max-w-2xl">
        <div className="sm:p-4">
          <div className="sm:border rounded-lg">
            <div className="flex justify-center items-center py-6 ">
              <div className="w-28 h-28 border rounded-full flex justify-center items-center p-1 bg-gray-100">
                {selected?.kyc?.userSelfie ? (
                  <img
                    src={selected?.kyc?.userSelfie}
                    alt="vip"
                    loading="lazy"
                    className="rounded-full w-full h-full"
                  />
                ) : (
                  <User className="w-full h-full text-gray-400" />
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 p-4 py-8 gap-4">
              <span className="w-full h-full">
                <h1 className="text-sm mb-1">VIP ID</h1>
                <input
                  readOnly
                  value={selected?.referralCode}
                  placeholder="Enter VIP ID"
                  className="border p-2 text-sm outline-none rounded-lg w-full"
                />
              </span>
              <span className="w-full h-full">
                <h1 className="text-sm mb-1">Email ID</h1>
                <input
                  readOnly
                  value={selected?.email}
                  placeholder="Enter Email ID"
                  className="border p-2 text-sm outline-none rounded-lg w-full"
                />
              </span>
              <span className="w-full h-full">
                <h1 className="text-sm mb-1">Mobile Number</h1>
                <input
                  readOnly
                  value={selected?.mobile}
                  placeholder="Enter Mobile Number"
                  className="border p-2 text-sm outline-none rounded-lg w-full"
                />
              </span>
              <span className="w-full h-full">
                <h1 className="text-sm mb-1">Current VTCN Balance</h1>
                <span className="border p-2 rounded-lg flex gap-2 items-center justify-center w-full">
                  <img
                    src="/images/vtcn.png"
                    alt="VTCN"
                    loading="lazy"
                    className="w-5 h-5"
                  />
                  <input
                    readOnly
                    value={balance?.toLocaleString()}
                    placeholder="Enter VIP ID"
                    className=" text-sm outline-none w-full"
                  />
                </span>
              </span>
              <span className="w-full h-full">
                <h1 className="text-sm mb-1">Subscription Type</h1>
                <select
                  onChange={(e) =>
                    setSubsId(
                      renewOptions?.data.find(
                        (opt) => opt._id === e.target.value
                      )
                    )
                  }
                  value={subsId._id || ""}
                  className="border p-2 rounded-lg w-full outline-none text-sm"
                >
                  {renewOptions?.data.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.type} ({option.name})
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>

          <div className="flex items-center justify-center gap-4 pt-8 text-sm p-4 sm:pb-0 w-full">
            <button
              disabled={checkoutMutation.isLoading}
              onClick={handleCheckout}
              className={`bg-bg-color hover:bg-opacity-85 rounded-lg w-full sm:w-40 h-10 text-white disabled:cursor-not-allowed ${
                checkoutMutation.isLoading ? "bg-gray-400" : ""
              }`}
            >
              {checkoutMutation.isLoading ? "Processing..." : "Recharge"}
            </button>
            <button
              onClick={() => setModal("")}
              className="border border-bg-color rounded-lg w-full sm:w-40 h-10"
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default RenewVipSubModal;
