import React from "react";
import { adManageArr } from "../../array/adsManage";
import { Pencil } from "lucide-react";
import { manageVipSubs } from "../../array/vipSubsArr";
import { formatDateMonthYear } from "../../utils/formatTime";

const ManageVipSubsTable = ({
  subscribers,
  setModal,
  selected,
  setSelected,
}) => {
  // console.log("subscribers", subscribers);

  return (
    <>
      <div className="overflow-x-auto overflow-y-hidden">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden rounded-lg border border-custom-border">
            <table className="min-w-full">
              <thead className="bg-sub-card border-b  border-custom-border bg-gradient-to-t from-custom-border to-bg-color text-white">
                <tr>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Serial No.
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    VIP ID
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Member Name
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Mobile Number
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Subscription Expiry
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Last Renewal Date
                  </th>
                  <th
                    scope="col"
                    className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td className="p-4 whitespace-nowrap text-sm">1.</td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    {subscribers?.referralCode}
                  </td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    {subscribers?.name}
                  </td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    {subscribers?.email}
                  </td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    {subscribers?.mobile}
                  </td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    {subscribers?.subscriptionExpiresAt
                      ? formatDateMonthYear(subscribers?.subscriptionExpiresAt)
                      : "-"}
                  </td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    {subscribers?.subscriptions[0]
                      ? formatDateMonthYear(
                          subscribers?.subscriptions[0].renewedAt
                        )
                      : "-"}
                  </td>
                  <td className="p-4 whitespace-nowrap text-sm">
                    <button
                      onClick={() => {
                        setModal("renew");
                        setSelected(subscribers);
                      }}
                      className="flex items-center gap-2"
                    >
                      <Pencil className="w-4 h-4" stroke="green" /> Renew
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageVipSubsTable;
