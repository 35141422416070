import React, { useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { CircleAlert, Search, X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { useQuery } from "react-query";
import { getUser } from "../../services/auth";
import { fetchUsers, searchUser } from "../../services/statementServices";
import StatementUserTable from "../Tables/StatementUserTable";
import Pagination from "../Pagination";

const StatementDownloadModal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const limit = 6;
  const { setModal } = useAppContext();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const { data: searchedUser, isLoading } = useQuery({
    queryKey: ["searchedUser", currentPage, limit, searchQuery],
    queryFn: async () => {
      const user = await getUser();
      const res = await searchUser(user, searchQuery);
      return res.data;
    },
    onSuccess: (data) => {
      setTotalPages(data?.data?.totalPages || 1);
    },
    onError: (error) => {
      console.error(error);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const renderContent = () => {
    if (searchedUser === null) {
      return (
        <div className="max-h-96 h-full py-16 flex flex-col gap-1 items-center">
          <CircleAlert className="w-5 h-5" />
          <p>User Not Found</p>
        </div>
      );
    }

    return (
      <div className="mt-4 w-full h-full p-4 border rounded-2xl shadow-lg">
        <StatementUserTable
          searchedUser={searchedUser}
          currentPage={currentPage}
          limit={limit}
        />
        <div className="mt-4">
          {totalPages > 1 && (
            <div className="mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-2xl">
        <div className="flex justify-between p-4 border-b shadow-sm">
          <h1 className="text-lg font-semibold">Statement Download</h1>
          <button
            className="hover:bg-gray-100 hover:text-red-500 rounded-full p-1"
            onClick={() => setModal("")}
          >
            <X />
          </button>
        </div>
        <div className="p-4 h-full w-full mt-2">
          <div className="flex items-center gap-2 p-1 h-12 border rounded-full shadow-md">
            <span className="ml-1">
              <Search className="w-6 h-6 text-gray-500 " />
            </span>
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              type="search"
              placeholder="Search"
              className="w-full h-full outline-none text-sm p-2"
            />
            <div className="">
              <button className="bg-bg-color w-16 h-full p-2 rounded-full text-white">
                GO
              </button>
            </div>
          </div>

          {isLoading ? (
            <div className="max-h-96 h-full py-16 flex flex-col gap-1 items-center">
              <p>Loading...</p>
            </div>
          ) : (
            renderContent()
          )}
        </div>
      </ModalLayout>
    </>
  );
};

export default StatementDownloadModal;
